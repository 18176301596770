/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"

const BioContainer = styled.div`
  margin: 20px 0;
`
const BioImage = styled(Image)`
  border-radius: 100%;
  display: inline-block;
  margin-right: 5px;
`
const BioDescription = styled.p`
  min-width: 300px;
  height: 50px;
  vertical-align: top;
  width: 50%;
  display: inline-block;
  padding: 0;
  margin: 0;
`

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <BioContainer>
      <BioImage fixed={data.avatar.childImageSharp.fixed} alt={author.name} />
      <BioDescription>
        Written by <strong>{author.name}</strong> {author.summary}{" "}
        <a href={`https://twitter.com/${social.twitter}`}>
          You should follow him on Twitter.
        </a>
      </BioDescription>
    </BioContainer>
  )
}

export default Bio
